import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import DriverLessonQuiz from './components/DriverLessonQuiz';
import DriverLesson from './components/DriverLesson';
import PrivacyPolicy from './components/PrivacyPolicy';
import { Helmet } from 'react-helmet';

const HeroSection = lazy(() => import('./components/HeroSection'));
const Timeline = lazy(() => import('./components/Timeline'));
const DonationForm = lazy(() => import('./components/DonationForm'));
const CampLocation = lazy(() => import('./components/CampLocation'));
const ImmersiveGallery = lazy(() => import('./components/ImmersiveGallery'));
const InteractiveEventCalendar = lazy(() => import('./components/InteractiveEventCalendar'));
const ChatWindow = lazy(() => import('./components/Chatbot/ChatWindow'));

function App() {
  const [showDriverLesson, setShowDriverLesson] = useState(false);
  const [showDriverQuiz, setShowDriverQuiz] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleDriverLesson = () => {
    setShowDriverLesson(!showDriverLesson);
    setIsModalOpen(!showDriverLesson);
  };

  const toggleDriverQuiz = () => {
    setShowDriverQuiz(!showDriverQuiz);
    setIsModalOpen(!showDriverQuiz);
  };

  const handleLessonComplete = (completedUserInfo) => {
    setUserInfo(completedUserInfo);
    setShowDriverLesson(false);
    setShowDriverQuiz(true);
    setIsModalOpen(true);
  };

  const HomePage = () => (
    <>
      <section id="hero">
        <HeroSection pauseAnimation={isModalOpen} />
      </section>
      <section id="timeline">
        <Timeline />
      </section>
      <section id="donate">
        <DonationForm />
      </section>
      <section id="location">
        <CampLocation />
      </section>
      <section id="gallery">
        <ImmersiveGallery />
      </section>
      <section id="events">
        <InteractiveEventCalendar />
      </section>
      <button
        onClick={toggleDriverLesson}
        className="fixed bottom-4 right-4 bg-purple-600 text-white px-4 py-2 rounded-full shadow-lg hover:bg-purple-700 transition-colors z-50"
      >
        Driver Training
      </button>
      {showDriverLesson && <DriverLesson onClose={toggleDriverLesson} onComplete={handleLessonComplete} />}
      {showDriverQuiz && <DriverLessonQuiz onClose={toggleDriverQuiz} userInfo={userInfo} />}
    </>
  );

    return (
      <Router>
        <div className="App h-screen flex flex-col">
          <Helmet>
            <title>{window.location.pathname === '/dusty' ? 'Dusty AI' : 'Smile High'}</title>
          </Helmet>
          <Navigation />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/dusty" element={<ChatWindow />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
          </Suspense>
        </div>
      </Router>
    );
  }

export default App;