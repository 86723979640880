import React from 'react';
import { motion } from 'framer-motion';

const PrivacyPolicy = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto px-4 py-8 max-w-4xl"
      >
        <div className="mt-[100px]">
          <h1 className="text-4xl font-bold text-purple-600 mb-6">Privacy Policy (or "How We Promise Not to Screw You Over")</h1>
          <p className="text-lg text-gray-700 mb-8">Welcome, you beautiful dust-covered unicorns, to our Privacy Policy page. Buckle up, because we're about to explain how we collect, use, and protect your information without putting you to sleep. Let's dive in!</p>
        </div>

        <motion.section
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold text-purple-500 mb-4">The Dirt We Dig Up (Information We Collect)</h2>
          <p className="text-gray-700 mb-4">We collect the following types of information, but don't worry, we're not as nosy as your ex:</p>
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            <li><span className="font-semibold">Your Burning Questions:</span> We collect and store parsed user queries to improve our services. Don't fret, these queries are as anonymous as your 3 am playa shenanigans.</li>
            <li><span className="font-semibold">Your Dusty Identity:</span> When you submit a form, we snag your name and email address. We use this to provide you with the services you request and occasionally to remind you that you're awesome.</li>
            <li><span className="font-semibold">Server Logs:</span> All activity may be logged by DreamHost Web Hosting. It's like the Black Rock Rangers, but for our digital playground.</li>
            <li><span className="font-semibold">Query and Response Data:</span> All queries and responses are sent between the server and client. While we use standard protections, please note that they could be susceptible to interception. Think of it like shouting across the playa - someone might overhear.</li>
          </ul>
        </motion.section>

        <motion.section
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold text-purple-500 mb-4">What We Do With Your Secrets (How We Use Your Information)</h2>
          <p className="text-gray-700 mb-4">We use the information we collect to:</p>
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            <li>Make our app cooler than a dust storm in an ice cream truck.</li>
            <li>Keep you in the loop about your burning questions and requests.</li>
            <li>Occasionally slide into your inbox with app improvements and Smile High events that'll knock your fuzzy boots off.</li>
          </ul>
        </motion.section>

        <motion.section
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold text-purple-500 mb-4">Fort Knox Ain't Got Nothin' On Us (Data Protection)</h2>
          <p className="text-gray-700">We guard your data like it's the last cup of coffee on the playa. We've got more security measures than you have blinky lights, protecting your info from unauthorized access, alteration, or destruction.</p>
        </motion.section>

        <motion.section
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.8 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold text-purple-500 mb-4">No Selling Out Here</h2>
          <p className="text-gray-700">Listen up, because this is important: We will NEVER sell your data. Not for all the bacon in Black Rock City. Your information is yours, and we respect that more than we respect the holy trinity of water, sunscreen, and hand sanitizer. We might analyze it and share insights to help improve Burning Man, Smile High HQ, and our app.</p>
        </motion.section>

        <motion.section
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold text-purple-500 mb-4">Keeping In Touch (Because We Miss You)</h2>
          <p className="text-gray-700 mb-4">We may occasionally reach out via email to:</p>
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            <li>Ask for your feedback to make our app even more fabulous.</li>
            <li>Share upcoming Smile High events that'll make your heart sing louder than a sound camp at dawn.</li>
            <li>Remind you that you're part of this amazing community (as if you could forget).</li>
          </ul>
        </motion.section>

        <motion.section
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.2 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold text-purple-500 mb-4">Your Local Stash (Chat Logs and Saved Items)</h2>
          <p className="text-gray-700 mb-4">Here's the deal with your chat logs and saved items:</p>
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            <li>We store them in your local browser, like a secret compartment in your dusty boots.</li>
            <li>This means we can't access them. It's just between you and your browser (how romantic).</li>
            <li>But here's the catch: we don't have user accounts. So if you clear your browser data, it's sayonara to your saved stuff.</li>
            <li>Our advice? Export anything important, like that life-changing conversation you had with our AI about the perfect playa outfit.</li>
          </ul>
          <p className="text-gray-700 mt-4">Remember, what happens in your browser, stays in your browser... unless you clear it. So treat your local data like your favorite playa gift - cherish it, and maybe make a copy or two.</p>
        </motion.section>

        <motion.section
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.4 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-semibold text-purple-500 mb-4">Give Us a Shout</h2>
          <p className="text-gray-700">Got questions? Concerns? A burning desire to share your favorite playa recipe? Hit us up at <a href="mailto:mike@smilehighbrc.org" className="text-purple-600 hover:text-purple-800 underline">mike@smilehighbrc.org</a>. We're all ears (even if they're full of dust).</p>
        </motion.section>
      </motion.div>
    </>
  );
};

export default PrivacyPolicy;
