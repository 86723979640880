import React, { useState, useCallback, useRef, useEffect, memo } from 'react';
import { X, ArrowRight, ArrowLeft } from 'lucide-react';

const lessonContent = [
    {
      title: "Required Personnel",
      content: "The Smile High vehicle requires a Driver and a Door Attendant. It's also recommended to have a Co-pilot and/or Spotters.",
      question: "Who are the required personnel for operating Smile High?",
      options: ["Driver and Co-pilot", "Driver and Door Attendant", "Driver and Spotter", "Co-pilot and Door Attendant"],
      correctAnswer: 1
    },
    {
      title: "Movement Conditions",
      content: "Movement is only allowed when entry doors are fully closed, the door switch light in the cab is off, and radio communication confirms the door is closed and latched.",
      question: "When is movement of the Smile High vehicle allowed?",
      options: ["When the driver is ready", "When the co-pilot gives the signal", "When entry doors are fully closed and verified", "When the engine is running"],
      correctAnswer: 2
    },
    {
      title: "Startup Procedure - Part 1",
      content: "The startup procedure must be followed in exact order:\n\n1) Ensure the Shift Lever is in neutral (N).\n2) Hold foot on Brake Pedal.\n3) Turn Ignition Key clockwise.",
      question: "What is the first step of the startup procedure?",
      options: ["Turn Ignition Key", "Hold foot on Brake Pedal", "Ensure Shift Lever is in neutral", "Press starter button"],
      correctAnswer: 2
    },
    {
      title: "Startup Procedure - Part 2",
      content: "After turning the Ignition Key, listen for 'click click click, click, click', pause, then another 'click, click, click, click'. Then press the starter button for 3-5 seconds until the engine starts.",
      question: "What should you do after hearing the second set of clicks?",
      options: ["Turn off the ignition", "Press the starter button", "Release the brake", "Shift into drive"],
      correctAnswer: 1
    },
    {
      title: "Startup Procedure - Part 3",
      content: "If the airbrake alarm is audible, wait until the air pressure indicator rises past the minimum level and the alarm turns off. Then release the air brake by pushing the lever down and slowly ease off the brake pedal.",
      question: "What should you do if the airbrake alarm is audible?",
      options: ["Ignore it and start driving", "Turn off the engine", "Wait for the alarm to stop before releasing the air brake", "Immediately release the air brake"],
      correctAnswer: 2
    },
    {
      title: "Stopping Procedure - Part 1",
      content: "To stop the vehicle:\n\n1) Slowly ease to a stop using the brake pedal.\n2) Pull the Air brake lever UP.\n3) Remove foot from brake pedal.",
      question: "What is the correct order for the first steps of the stopping procedure?",
      options: ["Brake pedal, Airbrake, Remove foot", "Airbrake, Brake pedal, Remove foot", "Remove foot, Brake pedal, Airbrake", "Brake pedal, Remove foot, Airbrake"],
      correctAnswer: 0
    },
    {
      title: "Stopping Procedure - Part 2",
      content: "After stopping, ensure the vehicle is NOT in gear and shifting. Note that the vehicle does not have a Park (P) position, so it's vital to ensure the shifter is in neutral (N) and the vehicle is stopped with the Airbrake set to prevent a runaway.",
      question: "Why is it important to set the Airbrake after stopping?",
      options: ["To save fuel", "To prevent a runaway", "To turn off the engine", "To lock the doors"],
      correctAnswer: 1
    },
    {
      title: "Critical Rules - Part 1",
      content: "Critical Rule for Startup:\n1) Release Airbrake\n2) Release Brake Pedal\n\nCritical Rule for Stopping:\n1) Press Brake Pedal (to completely stop)\n2) Activate Airbrake",
      question: "What is the correct order for releasing brakes during startup?",
      options: ["Brake Pedal then Airbrake", "Airbrake then Brake Pedal", "Release both simultaneously", "It doesn't matter"],
      correctAnswer: 1
    },
    {
      title: "Critical Rules - Part 2",
      content: "If at any time the warning light signaling the passenger door is illuminated, the vehicle must come to a complete stop using the stopping procedure.",
      question: "What should you do if the passenger door warning light is illuminated?",
      options: ["Ignore it and keep driving", "Slow down but continue moving", "Come to a complete stop using the stopping procedure", "Turn off the engine immediately"],
      correctAnswer: 2
    },
    {
      title: "Communication",
      content: "Proper communication is crucial for safe operation. The radio frequency for Smile High operations is 415.000.",
      question: "What is the radio frequency for Smile High operations?",
      options: ["411.000", "413.000", "415.000", "417.000"],
      correctAnswer: 2
    }
  ];

const UserInfoStep = memo(({ userInfo, handleUserInfoChange, inputRef }) => (
    <div>
        <h3 className="text-xl mb-4">Enter Your Information</h3>
        <input
            ref={inputRef}
            type="text"
            name="firstName"
            value={userInfo.firstName}
            onChange={handleUserInfoChange}
            className="w-full p-2 border rounded-lg mb-4"
            placeholder="First Name"
            required
        />
        <input
            type="text"
            name="lastName"
            value={userInfo.lastName}
            onChange={handleUserInfoChange}
            className="w-full p-2 border rounded-lg mb-4"
            placeholder="Last Name"
            required
        />
        <input
            type="email"
            name="email"
            value={userInfo.email}
            onChange={handleUserInfoChange}
            className="w-full p-2 border rounded-lg mb-4"
            placeholder="Email"
            required
        />
    </div>
));

const DriverLesson = memo(({ onClose, onComplete }) => {
    const [currentStep, setCurrentStep] = useState(-1);
    const [showQuestion, setShowQuestion] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', email: '' });

    const inputRef = useRef(null);

    useEffect(() => {
        if (currentStep === -1 && inputRef.current) {
            inputRef.current.focus();
        }
    }, [currentStep]);

    const handleUserInfoChange = useCallback((e) => {
        const { name, value } = e.target;
        setUserInfo(prevInfo => ({ ...prevInfo, [name]: value }));
    }, []);

    const handleNext = useCallback(() => {
        if (currentStep === -1) {
            if (userInfo.firstName && userInfo.lastName && userInfo.email) {
                setCurrentStep(currentStep + 1);
            } else {
                alert("Please fill in all fields");
            }
        } else if (showQuestion) {
            if (selectedAnswer === lessonContent[currentStep].correctAnswer) {
                if (currentStep < lessonContent.length - 1) {
                    setCurrentStep(currentStep + 1);
                    setShowQuestion(false);
                    setSelectedAnswer(null);
                } else {
                    onComplete(userInfo);
                }
            } else {
                alert("Please select the correct answer to proceed.");
            }
        } else {
            setShowQuestion(true);
        }
    }, [currentStep, showQuestion, selectedAnswer, userInfo, onComplete]);

    const handlePrevious = useCallback(() => {
        if (showQuestion) {
            setShowQuestion(false);
        } else if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            setSelectedAnswer(null);
        }
    }, [currentStep, showQuestion]);

    const handleAnswerSelect = useCallback((index) => {
        setSelectedAnswer(index);
    }, []);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300">
            <div className="bg-white rounded-lg p-8 max-w-2xl w-full transition-transform transform scale-95 duration-300">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Smile High Driver Lesson</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X size={24} />
                    </button>
                </div>

                {currentStep === -1 ? (
                    <UserInfoStep userInfo={userInfo} handleUserInfoChange={handleUserInfoChange} inputRef={inputRef} />
                ) : currentStep >= 0 && currentStep < lessonContent.length ? (
                    !showQuestion ? (
                        <div>
                            <h3 className="text-xl mb-4">{lessonContent[currentStep].title}</h3>
                            <p className="mb-4 whitespace-pre-line">{lessonContent[currentStep].content}</p>
                        </div>
                    ) : (
                        <div>
                            <h3 className="text-xl mb-4">Question</h3>
                            <p className="mb-4">{lessonContent[currentStep].question}</p>
                            {lessonContent[currentStep].options.map((option, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleAnswerSelect(index)}
                                    className={`block w-full text-left p-2 mb-2 rounded ${
                                        selectedAnswer === index
                                            ? selectedAnswer === lessonContent[currentStep].correctAnswer
                                                ? 'bg-green-200'
                                                : 'bg-red-200'
                                            : 'bg-gray-100'
                                    } hover:bg-purple-100`}
                                >
                                    {option}
                                </button>
                            ))}
                            {selectedAnswer !== null && (
                                <p className={`mt-4 ${selectedAnswer === lessonContent[currentStep].correctAnswer ? 'text-green-600' : 'text-red-600'}`}>
                                    {selectedAnswer === lessonContent[currentStep].correctAnswer ? 'Correct!' : 'Incorrect. Please try again.'}
                                </p>
                            )}
                        </div>
                    )
                ) : (
                    <div>Lesson complete!</div>
                )}

                <div className="flex justify-between mt-6">
                    <button
                        onClick={handlePrevious}
                        disabled={currentStep === -1}
                        className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50 flex items-center transition-transform duration-300 hover:scale-105"
                    >
                        <ArrowLeft size={18} className="mr-2" />
                        Previous
                    </button>
                    <button
                        onClick={handleNext}
                        disabled={(currentStep === -1 && (!userInfo.firstName || !userInfo.lastName || !userInfo.email)) || (showQuestion && selectedAnswer === null)}
                        className="px-4 py-2 bg-purple-600 text-white rounded flex items-center transition-transform duration-300 hover:scale-105"
                    >
                        {currentStep === -1 ? 'Start Lesson' : currentStep < lessonContent.length - 1 || !showQuestion ? 'Next' : 'Complete Lesson'}
                        <ArrowRight size={18} className="ml-2" />
                    </button>
                </div>
            </div>
        </div>
    );
});

export default DriverLesson;