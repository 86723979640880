import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, CheckCircle, AlertCircle } from 'lucide-react';
import jsPDF from 'jspdf';

const questions = [
    {
      question: "What are the required persons for operating Smile High?",
      options: [
        "Driver and Co-pilot",
        "Driver and Door Attendant",
        "Driver and Spotter",
        "Co-pilot and Door Attendant"
      ],
      correctAnswer: 1
    },
    {
      question: "When is movement of the Smile High vehicle allowed?",
      options: [
        "When the driver is ready",
        "When the co-pilot gives the signal",
        "When entry doors are fully closed and verified",
        "When the engine is running"
      ],
      correctAnswer: 2
    },
    {
      question: "What is the first step of the startup procedure?",
      options: [
        "Turn Ignition Key",
        "Hold foot on Brake Pedal",
        "Ensure Shift Lever is in neutral",
        "Press starter button"
      ],
      correctAnswer: 2
    },
    {
      question: "What should you do after hearing the second set of clicks during startup?",
      options: [
        "Turn off the ignition",
        "Press the starter button",
        "Release the brake",
        "Shift into drive"
      ],
      correctAnswer: 1
    },
    {
      question: "What should you do if the airbrake alarm is audible during startup?",
      options: [
        "Ignore it and start driving",
        "Turn off the engine",
        "Wait for the alarm to stop before releasing the air brake",
        "Immediately release the air brake"
      ],
      correctAnswer: 2
    },
    {
      question: "What is the correct order for the first steps of the stopping procedure?",
      options: [
        "Brake pedal, Airbrake, Remove foot",
        "Airbrake, Brake pedal, Remove foot",
        "Remove foot, Brake pedal, Airbrake",
        "Brake pedal, Remove foot, Airbrake"
      ],
      correctAnswer: 0
    },
    {
      question: "Why is it important to set the Airbrake after stopping?",
      options: [
        "To save fuel",
        "To prevent a runaway",
        "To turn off the engine",
        "To lock the doors"
      ],
      correctAnswer: 1
    },
    {
      question: "What is the correct order for releasing brakes during startup?",
      options: [
        "Brake Pedal then Airbrake",
        "Airbrake then Brake Pedal",
        "Release both simultaneously",
        "It doesn't matter"
      ],
      correctAnswer: 1
    },
    {
      question: "What should you do if the passenger door warning light is illuminated?",
      options: [
        "Ignore it and keep driving",
        "Slow down but continue moving",
        "Come to a complete stop using the stopping procedure",
        "Turn off the engine immediately"
      ],
      correctAnswer: 2
    },
    {
      question: "What is the radio frequency for Smile High operations?",
      options: ["411.000", "413.000", "415.000", "417.000"],
      correctAnswer: 2
    },
    {
      question: "What position should the Shift Lever be in before starting the engine?",
      options: ["Drive (D)", "Reverse (R)", "Neutral (N)", "Park (P)"],
      correctAnswer: 2
    },
    {
      question: "How long should you press the starter button during the startup procedure?",
      options: ["1-2 seconds", "3-5 seconds", "6-8 seconds", "10-12 seconds"],
      correctAnswer: 1
    }
  ];

const DriverLessonQuiz = ({ onClose, userInfo }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [answers, setAnswers] = useState({});
    const [showResults, setShowResults] = useState(false);
  

const handleAnswer = (questionIndex, answerIndex) => {
    setAnswers({ ...answers, [questionIndex]: answerIndex });
};

const handleNext = async () => {
    if (currentStep < questions.length) {
        setCurrentStep(currentStep + 1);
    } else {
        setShowResults(true);
        // Send quiz results to admin immediately
        await sendQuizResults();
    }
};

const sendQuizResults = async () => {
    const score = calculateScore();
    const formData = new FormData();
    formData.append('name', `${userInfo?.firstName || 'N/A'} ${userInfo?.lastName || 'N/A'}`);
    formData.append('email', userInfo?.email || 'N/A');
    formData.append('score', score);

    try {
        const response = await fetch('https://smilehighbrc.org/api/send_admin_email.php', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error('Failed to send quiz results to admin');
        }

        console.log('Quiz results sent to admin');
    } catch (error) {
        console.error('Error sending quiz results:', error);
    }
};

const handlePrevious = () => {
    setCurrentStep(Math.max(0, currentStep - 1));
};

const calculateScore = () => {
    let score = 0;
    questions.forEach((q, index) => {
    if (answers[index] === q.correctAnswer) score++;
    });
    return score;
};

const generateCertificate = async () => {
    if (calculateScore() === questions.length) {
        const doc = new jsPDF();
        const completionDate = new Date().toLocaleString();
        
        // Add background color
        doc.setFillColor(245, 243, 255);
        doc.rect(0, 0, 210, 297, 'F');
        
        // Add header
        doc.setFillColor(102, 51, 153);
        doc.rect(0, 0, 210, 40, 'F');
        
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(28);
        doc.setFont('helvetica', 'bold');
        doc.text("Smile High Driver Certification", 105, 25, null, null, 'center');
        
        // Add content
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(16);
        doc.setFont('helvetica', 'normal');
        doc.text(`This certifies that ${userInfo?.firstName || 'N/A'} ${userInfo?.lastName || 'N/A'}`, 105, 60, null, null, 'center');
        doc.text(`Email: ${userInfo?.email || 'N/A'}`, 105, 70, null, null, 'center');
        
        doc.setFontSize(14);
        doc.text(`Completed on: ${completionDate}`, 105, 80, null, null, 'center');
        
        doc.setFontSize(20);
        doc.setFont('helvetica', 'bold');
        doc.text(`Score: ${calculateScore()} / ${questions.length}`, 105, 95, null, null, 'center');
        
        // Add cheat sheet
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text("Driver Cheat Sheet:", 20, 115);
        
        doc.setFontSize(15);
        doc.setFont('helvetica', 'normal');
        const cheatSheet = [
          "1. Required: Driver and Door Attendant",
          "2. Move only when doors are closed and verified",
          "3. Startup: Neutral, Brake, Ignition, Starter, Release Airbrake",
          "4. Stopping: Brake, Airbrake, Neutral",
          "5. Always stop if passenger door warning light is on",
          "6. Radio Frequency: 415.000"
        ];
        doc.text(cheatSheet, 30, 130);
        
        // Add footer
        doc.setFillColor(102, 51, 153);
        doc.rect(0, 277, 210, 20, 'F');
        
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');
        doc.text("Smile High - Burning Man 2024", 105, 290, null, null, 'center');
        
        // Convert PDF to Blob
        const pdfBlob = doc.output('blob');
        const formData = new FormData();
        formData.append('name', `${userInfo?.firstName || 'N/A'} ${userInfo?.lastName || 'N/A'}`);
        formData.append('email', userInfo?.email || 'N/A');
        formData.append('score', calculateScore());
        formData.append('pdf', pdfBlob, 'SmileHighDriverCertificate.pdf');
        
        try {
            console.log('Sending request to server...');
            const response = await fetch('https://smilehighbrc.org/api/log_results.php', {
                method: 'POST',
                body: formData
            });

            console.log('Response status:', response.status);
            const responseText = await response.text();
            console.log('Response text:', responseText);

            let result;
            try {
                result = JSON.parse(responseText);
            } catch (e) {
                console.error('Error parsing JSON:', e);
                throw new Error('Invalid JSON response from server: ' + responseText);
            }

            if (response.ok && result.status === 'success') {
                alert('Certificate generated and email sent');
            } else {
                throw new Error(result.message || 'Unknown error');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to generate certificate and send email: ' + error.message);
        }

        // Save the PDF locally
        doc.save("SmileHighDriverCertificate.pdf");
    } else {
        alert("You need to score 12 out of 12 to generate the certificate.");
    }
};

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        className="bg-white rounded-lg p-8 max-w-2xl w-full"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Smile High Driver Certification</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
  
        {!showResults ? (
          <>
            {currentStep < questions.length ? (
              <div>
                <h3 className="text-xl mb-4">Question {currentStep + 1} of {questions.length}</h3>
                <p className="mb-4">{questions[currentStep].question}</p>
                {questions[currentStep].options.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleAnswer(currentStep, index)}
                    className={`block w-full text-left p-2 mb-2 rounded ${
                      answers[currentStep] === index ? 'bg-purple-200' : 'bg-gray-100'
                    } hover:bg-purple-100`}
                  >
                    {option}
                  </button>
                ))}
              </div>
            ) : (
              <div className="text-center">
                <h3 className="text-xl mb-4">Quiz Complete!</h3>
                <p>You've completed all questions. Click 'Next' to see your results.</p>
              </div>
            )}
          </>
        ) : (
          <div>
            <h3 className="text-xl mb-4">Quiz Results</h3>
            <p className="mb-4">You scored {calculateScore()} out of {questions.length}.</p>
            {calculateScore() === questions.length ? (
              <div className="mb-4">
                <CheckCircle className="text-green-500 inline-block mr-2" />
                Congratulations! You've passed the quiz.
              </div>
            ) : (
              <div className="mb-4">
                <AlertCircle className="text-yellow-500 inline-block mr-2" />
                You didn't pass. Please review the material and try again.
              </div>
            )}
            <button
              onClick={generateCertificate}
              className={`px-4 py-2 ${calculateScore() === questions.length ? 'bg-purple-600 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'} rounded mr-4`}
              disabled={calculateScore() !== questions.length}
            >
              Generate Certificate
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 rounded"
            >
              Close
            </button>
          </div>
        )}
  
        {currentStep !== -1 && !showResults && (
          <div className="flex justify-between mt-6">
            <button
              onClick={handlePrevious}
              disabled={currentStep === 0}
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-purple-600 text-white rounded"
            >
              {currentStep < questions.length ? 'Next' : 'See Results'}
            </button>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default DriverLessonQuiz;