import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isTransparent, setIsTransparent] = useState(true);
  const [dustyScrollProgress, setDustyScrollProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToSection = (hash) => {
    navigate('/');
    setTimeout(() => {
      window.location.hash = hash;
    }, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.pageYOffset;
      const scrollPercentage = (currentScroll / totalScroll) * 100;
      setScrollProgress(scrollPercentage);

      // Only make the navbar transparent on the homepage
      if (location.pathname === '/') {
        if (currentScroll > window.innerHeight && isTransparent) {
          setIsTransparent(false);
        } else if (currentScroll <= window.innerHeight && !isTransparent) {
          setIsTransparent(true);
        }
      } else {
        setIsTransparent(false); // Make it solid on other pages
      }
    };

    const handleDustyScroll = (event) => {
      setDustyScrollProgress(event.detail);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('dustyScroll', handleDustyScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('dustyScroll', handleDustyScroll);
    };
  }, [isTransparent, location.pathname]);

  const textShadowClass = isTransparent ? 'drop-shadow-[0_2px_2px_rgba(0,0,0,0.08)]' : '';

  // Determine which scroll progress to use
  const currentScrollProgress = location.pathname === '/dusty' ? dustyScrollProgress : scrollProgress;

  return (
    <motion.nav 
      className={`fixed top-0 left-0 right-0 z-50 h-16 md:h-auto ${isTransparent && !isOpen && window.innerWidth >= 768 ? 'bg-transparent' : 'bg-white shadow-md'} transition-all duration-300 font-body`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 relative">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className={`text-2xl font-bold text-purple-600 font-display ${textShadowClass}`}>Smile High 😊</Link>
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)} className={`text-purple-600 ${textShadowClass}`}>
              {isOpen ? <X /> : <Menu />}
            </button>
          </div>
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className={`text-purple-600 hover:text-yellow-300 transition-colors ${textShadowClass}`}>Home</Link>
            <a onClick={() => navigateToSection('#timeline')} className={`text-purple-600 hover:text-yellow-300 transition-colors ${textShadowClass}`}>Timeline</a>
            <a onClick={() => navigateToSection('#location')} className={`text-purple-600 hover:text-yellow-300 transition-colors ${textShadowClass}`}>Location</a>
            <a onClick={() => navigateToSection('#gallery')} className={`text-purple-600 hover:text-yellow-300 transition-colors ${textShadowClass}`}>Memories</a>
            <a onClick={() => navigateToSection('#events')} className={`text-purple-600 hover:text-yellow-300 transition-colors ${textShadowClass}`}>Schedule</a>
            <Link 
              to="/dusty" 
              className={`text-purple-600 hover:text-yellow-300 transition-colors ${
                window.location.pathname === '/dusty' ? 'font-bold' : ''
              } ${textShadowClass}`}
            >
              Dusty AI
            </Link>
            <motion.a 
              onClick={() => navigateToSection('#donate')}
              className={`bg-purple-600 text-white px-6 py-2 rounded-full font-bold hover:bg-purple-700 transition-colors ${isTransparent ? 'drop-shadow-[0_4px_4px_rgba(0,0,0,0.1)]' : ''}`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Donate Now
            </motion.a>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div 
            className="md:hidden absolute top-full left-0 right-0 bg-white shadow-md"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex flex-col items-center space-y-4 py-4">
              <Link to="/" className="text-purple-600 hover:text-yellow-300 transition-colors">Home</Link>
              <a onClick={() => navigateToSection('#timeline')} className="text-purple-600 hover:text-yellow-300 transition-colors">Timeline</a>
              <a onClick={() => navigateToSection('#location')} className="text-purple-600 hover:text-yellow-300 transition-colors">Location</a>
              <a onClick={() => navigateToSection('#gallery')} className="text-purple-600 hover:text-yellow-300 transition-colors">Memories</a>
              <a onClick={() => navigateToSection('#events')} className="text-purple-600 hover:text-yellow-300 transition-colors">Schedule</a>
              <Link to="/dusty" className="text-purple-600 hover:text-yellow-300 transition-colors">Dusty AI</Link>
              <motion.a 
                onClick={() => navigateToSection('#donate')}
                className="bg-purple-600 text-white px-6 py-2 rounded-full font-bold hover:bg-purple-700 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Donate Now
              </motion.a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div 
        className="h-1 bg-gradient-to-r from-yellow-300 to-red-500" 
        style={{ width: `${currentScrollProgress}%` }}
      ></div>
    </motion.nav>
  );
};

export default Navigation;